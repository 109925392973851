@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/source-sans-pro/SourceSansPro-Light.eot');
  src: url('../../assets/fonts/source-sans-pro/SourceSansPro-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/source-sans-pro/SourceSansPro-Light.woff2')
      format('woff2'),
    url('../../assets/fonts/source-sans-pro/SourceSansPro-Light.woff')
      format('woff'),
    url('../../assets/fonts/source-sans-pro/SourceSansPro-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../assets/fonts/source-sans-pro/SourceSansPro-SemiBold.eot');
  src: url('../../assets/fonts/source-sans-pro/SourceSansPro-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/source-sans-pro/SourceSansPro-SemiBold.woff2')
      format('woff2'),
    url('../../assets/fonts/source-sans-pro/SourceSansPro-SemiBold.woff')
      format('woff'),
    url('../../assets/fonts/source-sans-pro/SourceSansPro-SemiBold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
